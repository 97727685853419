<template>
  <div>
    <div class="tab">
      <div class="tab-list" @click="$emit('toggletype',0)">登录</div>
      <div class="tab-list tab-list-active">注册</div>
    </div>
    <div class="form">
      <div class="input-box">
        <input class="input" placeholder="请输入手机号" v-model="loginForm.phone" type="text">
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入密码" v-model="loginForm.password" :type="pwd1show ? 'text' : 'password'">
        <div class="toggle-password" @click="pwd1show = !pwd1show">
          <img v-if="!pwd1show" src="@/assets/images/login/pwd-1.png" alt="">
          <img v-if="pwd1show" src="@/assets/images/login/pwd-2.png" alt="">
        </div>
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入密码" v-model="loginForm.passwordNew" :type="pwd2show ? 'text' : 'password'">
        <div class="toggle-password" @click="pwd2show = !pwd2show">
          <img v-if="!pwd2show" src="@/assets/images/login/pwd-1.png" alt="">
          <img v-if="pwd2show" src="@/assets/images/login/pwd-2.png" alt="">
        </div>
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入验证码" v-model="loginForm.code" maxlength="6" type="text">
        <GetVsCode :phone="loginForm.phone" />
      </div>
      <div class="input-box">
        <input class="input" placeholder="请输入图形验证码" v-model="loginForm.validateCode" type="text">
        <div class="vcode" @click="createImageCode">
          <img v-if="vcodeImg" :src="vcodeImg" alt="点击获取图片验证码">
        </div>
      </div>
      
      <!-- <div class="button-box">
        <span>手机短信登录</span>
        <span>忘记密码</span>
      </div> -->
    </div>
    <button class="confirm" @click="register">注册</button>
    <div class="has-account" @click="$emit('toggletype',0)">已有账号立即登录</div>
    <div class="protocol">
      <img v-if="!isRead" @click="isRead = !isRead" src="@/assets/images/login/icon-1.png" alt="">
      <img v-if="isRead" @click="isRead = !isRead" src="@/assets/images/login/icon-2.png" alt="">
      <span>已阅读并同意<router-link to="/help/register" class="link" target="_blank">《用户注册协议》</router-link></span>
    </div>
  </div>
</template>

<script>
import { createImageCode, register } from '@/api/api'
import GetVsCode from '@/components/user/GetVsCode'

export default {
  name: "Regist",
  data() {
    return {
      pwd1show: false,
      pwd2show: false,
      loginForm: {
        phone: '',
        password: '',
        passwordNew: '',
        code: '',
        validateCode: '',
        volidateImageKey: ''
      },
      vcodeImg: null,
      isRead: false,
    }
  },
  components: {
    GetVsCode
  },
  mounted() {
    this.createImageCode();
  },
  methods: {
    checkForm() {
      // let state = true;
      let loginForm = this.loginForm
      if(loginForm.phone === '') {
        this.$message.error('手机号码不能为空')
        return false
      }
      if(loginForm.password === '') {
        this.$message.error('密码不能为空')
        return false
      }
      if(loginForm.passwordNew === '') {
        this.$message.error('新密码不能为空')
        return false
      }
       if(loginForm.code === '') {
        this.$message.error('手机验证码不能为空')
        return false
      }
      
      if(loginForm.validateCode === '') {
        this.$message.error('图形验证码不能为空')
        return false
      }
      if(!/^1[3456789]\d{9}$/.test(loginForm.phone)) {
        this.$message.error('手机号码格式不正确')
        return false
      }
      // if(!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(loginForm.password)) {
      //   this.$message.error('密码为数字加字母，6-12位')
      //   return false
      // }
      if(loginForm.password !== loginForm.passwordNew) {
        this.$message.error('两次输入的密码不一样')
        return false
      }
      if(!this.isRead) {
        this.$message.error('请先阅读并同意软件服务协议及隐私政策')
        return false
      }
      return true
    },
    async createImageCode() {
      let response = await createImageCode({});
      this.loginForm.volidateImageKey = response.headers.volidateimagekey
      const blob = new Blob([response.data], {
        type: 'application/jpg;charset=utf-8',
      });
      this.vcodeImg = window.URL.createObjectURL(blob)
    },
    // 注册
    async register() {
      if(!this.checkForm()) {
        return false
      }
      let params = this.loginForm;
      let response = await register(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      }
      this.$message.success(response.returnMsg)
      this.$emit('toggletype',0)
      // this.$store.commit('login/hideDialog')
    }
  }
}
</script>
<style lang="less" scoped>
 .tab{
      display: flex;
      justify-content: space-around;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-bottom: 20px;
      .tab-list{
        border-bottom: 2px solid #fff;
        width: 50px;
        cursor: pointer;
      }
      .tab-list-active{
        border-bottom: 2px solid #FF3F47;
        color: #333333;
        font-weight: bold;
      }
    }
    .form{
      margin-bottom: 25px;
    }
    .button-box{
      height: 58px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      font-size: 14px;
      color: #999999;
      justify-content: space-between;
      span{
        cursor: pointer;
      }
    }
    .input-box{
      border-bottom: 1px solid #E8E8E8;
      height: 58px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      font-size: 14px;
      .input{
        width: 100%;
        height: 58px;
        line-height: 58px;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
    .confirm{
      width: 370px;
      height: 49px;
      line-height: 49px;
      background: #FF3F47;
      border-radius: 6px;
      font-size: 21px;
      // font-weight: bold;
      color: #FFFFFF;
      text-align: center;
    }
    .vcode{
      width: 90px;
      height: 23px;
      background: #eee;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }
    .toggle-password{
      width: 20px;
      height: 12px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
    }
    .has-account{
      color: #FF3F47;
      margin-top: 28px;
      text-align: center;
      font-size: 17px;
      cursor: pointer;
    }
    .protocol{
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: #6F6F6F;
      // cursor: pointer;
      margin-top: 20px;
      user-select: none;
      img{
        display: block;
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }
      .link {
        color: #FF3F47;
        text-decoration: none;
      }
    }
</style>
