/*
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2022-03-31 10:57:32
 * @LastEditors  : BigBigger
 */
import request from './request'


export const test = (params) => {
  return request({
    url: '/api/index/courseGroup',
    method: 'POST',
    params
  })
}
import axios from 'axios'
const changeUrl = (url, proxy = '/dev-api') => {
  let nowUrl = url
  // if(process.env.MODE === 'dev') {
  //   if(!process.server) {
  //     nowUrl = `${proxy}${config.url}`
  //   }
  // }
  // if(!process.server) {
  //   nowUrl = `${proxy}${url}`
  // }
  return nowUrl
}

const uploadFileRequest = (url,method = 'post', queryParams) => {
  return axios({
    method: method,
    //url: `https://web-platfrom.bxgwy.com/${url}`,
    // url: `http://47.110.77.30:9900/${url}`,
    url: `${process.env.VUE_APP_BASE_URL}${url}`,
    params: queryParams,
    responseType: 'blob',
  })
}

// 首页轮播图
export const getBanner = (params) => {
  return request({
    url: '/api/index/carousel',
    method: 'POST',
    params
  })
}
// 查询课程分类
export const getCourseGroupCategroy = (params) => {
  return request({
    url: '/api/index/courseGroupCategroy',
    method: 'GET',
    params
  })
}
// 查询课程分类
export const getCourseGroupSecondCategroy = (params) => {
  return request({
    url: '/api/index/courseGroupSecondCategroy',
    method: 'GET',
    params
  })
}

// 查询课程组
export const getCourseGroup = (params) => {
  return request({
    url: '/api/index/courseGroup',
    method: 'GET',
    params
  })
}

// 查询课程组详情
export const getCourseGroupDetail  = (params) => {
  return request({
    url: changeUrl('/api/courseGroup/detail'),
    method: 'POST',
    params
  })
}

// 生成图片验证码
export const createImageCode = (params) => {
  // return request({
  //   url: ' /api/code/createImageCode',
  //   method: 'POST',
  //   params
  // })
  return uploadFileRequest('/api/code/createImageCode')
}

// 发送手机验证码
export const createPhoneCode = (params) => {
  return request({
    url: '/api/code/createPhoneCode',
    method: 'POST',
    params
  })
}

// 注册
export const register = (params) => {
  return request({
    url: '/api/user/register',
    method: 'POST',
    params
  })
}

export const logout = (params) => {
  return request({
    url: '/api/user/logout',
    method: 'POST',
    params
  })
}

// 找回密码
export const forgetPassword = (params) => {
  return request({
    url: '/api/user/forgetPassword',
    method: 'POST',
    params
  })
}

// 密码登录
export const loginByPassword = (params) => {
  return request({
    url: '/api/login/password',
    method: 'POST',
    params
  })
}

// 验证码登录
export const loginByCode = (params) => {
  return request({
    url: '/api/login/phone',
    method: 'POST',
    params
  })
}


// 修改密码
export const changePassword = (params) => {
  return request({
    url: '/api/user/changePassword',
    method: 'POST',
    params
  })
}

//我的课程组
export const getOwnCourseGroup = (params) => {
  return request({
    url: '/api/courseGroup/ownCourseGroup',
    method: 'POST',
    params
  })
}

// 查询课程组封面/ 配套课程组
export const getCourseGroupCover = (params) => {
  return request({
    url: '/api/courseGroup/cover',
    method: 'POST',
    params
  })
}

// 查询岗位的地区
export const getJobPlaceName = (params) => {
  return request({
    url: '/api/job/placeName',
    method: 'POST',
    params
  })
}

// 我的订单列表
export const getOrderList = (params) => {
  return request({
    url: '/api/order/list',
    method: 'get',
    params
  })
}

// 获取职位列表
export const getJobList = (params) => {
  return request({
    url: '/api/job/pageWeb',
    method: 'POST',
    params
  })
}

// 获取收货地址
export const getAddressList = (params) => {
  return request({
    url: '/api/address/list',
    method: 'POST',
    params
  })
}


// 新增收货地址
export const addAddress = (params) => {
  return request({
    url: '/api/address/add',
    method: 'POST',
    params
  })
}

// 删除收货地址
export const deleteAddress = (params) => {
  return request({
    url: '/api/address/del',
    method: 'POST',
    params
  })
}

// 课程协议列表
export const getProtocolList = (params) => {
  return request({
    url: '/api/protocol/pageWeb',
    method: 'POST',
    params
  })
}

// 修改收货地址
export const updateAddress = (params) => {
  return request({
    url: '/api/address/update',
    method: 'POST',
    params
  })
}

// 修改用户信息
export const updateUserInfo = (params) => {
  return request({
    url: '/api/user/update',
    method: 'POST',
    params
  })
}

// 设置默认收货地址
export const setDefaultAddress = (params) => {
  return request({
    url: '/api/address/updateDefaultAddress',
    method: 'POST',
    params
  })
}

// 获取默认收货地址
export const getDefaultAddressList = (params) => {
  return request({
    url: '/api/address/defaultAddress',
    method: 'POST',
    params
  })
}

// 查询用户是否能下单
export const checkBeforeCreate = (params) => {
  return request({
    url: '/api/order/beforeCreate',
    method: 'GET',
    params
  })
}


// 创建订单
export const createOrder = (data) => {
  return request({
    url: '/api/order/create',
    method: 'POST',
    data
  })
}

// 订单详情
export const getOrderDetail = (params) => {
  return request({
    url: '/api/order/detail',
    method: 'GET',
    params
  })
}

// 订单修改个人信息
export const updatePersonalInfo = (params) => {
  return request({
    url: '/api/order/updatePersonalInfo',
    method: 'POST',
    params
  })
}

// 课程组提示语
export const getLessonGroupTips = (params) => {
  return request({
    url: '/api/courseGroup/lessonGroupTips',
    method: 'POST',
    params
  })
}

// 支付
export const pay = (data) => {
  return request({
    url: '/api/order/pay',
    method: 'POST',
    data
  })
}

// 取消订单
export const cancelOrder = (params) => {
  return request({
    url: '/api/order/cancel',
    method: 'POST',
    params
  })
}


// 获取课程详情
export const courseDetailWeb = (params) => {
  return request({
    url: changeUrl('/api/courseGroup/courseDetailWeb'),
    method: 'POST',
    params
  })
}

// 检查是否报满
export const checkFully = (params) => {
  return request({
    url: changeUrl('/api/job/checkFully'),
    method: 'POST',
    params
  })
}

// 生成直播用的签名
export const createSign = (data) => {
  return request({
    url: changeUrl('/api/blw/createSign'),
    method: 'POST',
    data
  })
}

// 获取iframe验证信息
export const getBlwParam = (params) => {
  return request({
    url: changeUrl('/api/blw/getBlwParam'),
    method: 'POST',
    params
  })
}

// 保利为，获取聊天室token
export const bGetToken = (params) => {
  return request({
    url: 'https://api.polyv.net/live/v3/channel/common/get-chat-token',
    method: 'GET',
    params
  })
}

// 获取用户购买课程组姓名 (暂时不用)
export const userBuyName = (params) => {
  return request({
    url: '/api/courseGroup/userBuyName',
    method: 'POST',
    params
  })
}

// 下单时获取用户信息
export const getPersonalInfo = (params) => {
  return request({
    url: '/api/orderPersonalInfo/default',
    method: 'GET',
    params
  })
}

export const getUserInfo = (params) => {
  return request({
    url: '/api/user/info',
    method: 'POST',
    params
  })
}

//通过班次id返回字符串
export const getFlightName = (data) => {
  return request({
    url: '/api/order/createFlightStr',
    method: 'POST',
    data
  })
}

// 获取课程协议
export const getProtocol = (params) => {
  return request({
    url: '/api/protocol/detail',
    method: 'POST',
    params
  })
}

// 疑问与帮助
export const getqQuestionHelp = (params) => {
  return request({
    url: '/api/questionHelp/page',
    method: 'POST',
    params
  })
}

// 判断是否有需要支付的订单
export const checkOrder = (params) => {
  return request({
    url: '/api/auditState/judge',
    method: 'POST',
    params
  })
}

function random_string(len = 32) {
  len = len || 32;
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return pwd;
}

export function upload({ file, onProgress }) {
  return request({
    url: '/api/upload/newfile',
    method: 'post'
  })
    .then(({ data }) => {
      const { dir, policy, accessid, signature, host } = data;
      const name = random_string() + '.' + (file).name.split('.').pop();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('policy', policy);
      formData.append('OSSAccessKeyId', accessid);
      formData.append('signature', signature);
      formData.append('key', dir + name);
      formData.append('file', file);
      formData.append('success_action_status', '200');
      return request({
        url: host,
        data: formData,
        method: 'post',
        onUploadProgress: (e) => {
          if (e.total > 0) {
            e.percent = (e.loaded / e.total) * 100;
          }
          onProgress?.(e);
        },
      })
        .then((res) => {
          return Promise.resolve({
            data: {
              src: host + '/' + dir + name,
            },
          });
        }).catch(err=> {
          return Promise.reject(err);
        });
    });
}