import { render, staticRenderFns } from "./GetVsCode.vue?vue&type=template&id=eb601474&scoped=true"
import script from "./GetVsCode.vue?vue&type=script&lang=js"
export * from "./GetVsCode.vue?vue&type=script&lang=js"
import style0 from "./GetVsCode.vue?vue&type=style&index=0&id=eb601474&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb601474",
  null
  
)

export default component.exports